import React, { useState, useEffect } from "react";
import Select from "react-select";

import { ModalSubmit } from "../../../../components/ModalPadrao";
import { isEmpty } from "../../../../components/Util";
import api from "../../../../service/api";

const ModalAcidenteMedidaControleNecessaria = ({
  show,
  setShow,
  titulo,
  corTitulo,
  medidaControleNecessaria,
  buscarMedidasControleNecessaria,
  novaMedidaControleNecessaria,
  acidenteMedidasControleNecessaria,
  gruposMedidas,
  editando,
}) => {
  const [salvarMedidaControleNecessaria, setSalvarMedidaControleNecessaria] = useState(
    medidaControleNecessaria ? medidaControleNecessaria.definicao : novaMedidaControleNecessaria
  );
  const [salvarPrazoMedidaControleNecessaria, setSalvarPrazoMedidaControleNecessaria] = useState(
    medidaControleNecessaria?.prazo
  );
  const [salvarFormaAcompanhamentoMedidaControleNecessaria, setSalvarFormaAcompanhamentoMedidaControleNecessaria] =
    useState(medidaControleNecessaria?.forma_acompanhamento_id);
  const [formasAcompanhamento, setFormasAcompanhmanto] = useState([]);
  const [medidaNecessariaId, setMedidaNecessariaId] = useState(medidaControleNecessaria?.medida_controle_grupo_id);

  useEffect(() => {
    buscarFormasAcompanhamento();
  }, []);

  async function buscarFormasAcompanhamento() {
    await api.get("/baseDados/acidenteBaseDados/obterFormasAcompanhamento").then((resposta) => {
      setFormasAcompanhmanto(resposta.data);
    });
  }

  async function salvarBanco(e) {
    e.preventDefault();
    if (isEmpty(medidaControleNecessaria)) {
      api.post("/baseDados/acidenteBaseDados/inserirMedidaControleNecessaria", {
        definicao: salvarMedidaControleNecessaria,
        medida_controle_grupo_id: medidaNecessariaId,
        prazo: salvarPrazoMedidaControleNecessaria,
        forma_acompanhamento_id: salvarFormaAcompanhamentoMedidaControleNecessaria,
      }).then(() => {
        buscarMedidasControleNecessaria();
        setShow(false);
      })
      .catch((erro) => alert(erro.response.data.mensagem));
    } else {
      api.put(`/baseDados/acidenteBaseDados/editarMedidaControle`, {
        id: medidaControleNecessaria.id,
        definicao: salvarMedidaControleNecessaria,
        medida_controle_grupo_id: medidaNecessariaId,
        prazo: salvarPrazoMedidaControleNecessaria,
        forma_acompanhamento_id: salvarFormaAcompanhamentoMedidaControleNecessaria,
      }).then(() => {
        buscarMedidasControleNecessaria();
        setShow(false);
      })
      .catch((erro) => alert(erro.response.data.mensagem));
    }
  }

  const optionsGruposMedidas = gruposMedidas?.map((item) => ({
    value: item.id,
    label: item.grupo,
  }));

  let optionsFormaAcompanhamento = formasAcompanhamento.map((item) => ({
    label: item.acompanhamento,
    value: item.id,
  }));

  function selecionaFormaAcompanhamento(forma_acompanhamento_id) {
    if (forma_acompanhamento_id === "1") {
      return { label: "Inspeção no local" };
    } else if (forma_acompanhamento_id === "3") {
      return { label: "Análise documental" };
    } else if (forma_acompanhamento_id === "5") {
      return { label: "Ficha EPI" };
    } else if (forma_acompanhamento_id === "7") {
      return { label: "Nenhum" };
    } else if (forma_acompanhamento_id === "11") {
      return { label: "Registro(assinatura)" };
    } else {
      return { label: "--" };
    }
  }

  return (
    <ModalSubmit
      show={show}
      setShow={setShow}
      titulo={titulo}
      corTitulo={corTitulo}
      tamanho="md"
      onSubmit={(e) => salvarBanco(e)}
    >
      <div className="row">
        <div className="col">
          <label>Medida de Controle Necessária:</label>

          <input
            type="text"
            id="medidaControleNecessaria"
            name="medidaControleNecessaria"
            className="form-control"
            defaultValue={medidaControleNecessaria ? salvarMedidaControleNecessaria : novaMedidaControleNecessaria}
            onChange={(m) => {
              setSalvarMedidaControleNecessaria(m.target.value);
            }}
            required
          />
        </div>
        <div className="col-4">
          <label htmlFor="medidaGrupo" className="form-label">
            Medida Grupo:
          </label>
          <Select
            placeholder="Selecione..."
            name="medida grupo"
            id="medidaGrupo"
            defaultValue={
              medidaControleNecessaria && {
                value: medidaControleNecessaria.medida_controle_grupo_id,
                label: optionsGruposMedidas.find(
                  (grupoMedida) => grupoMedida.value === medidaControleNecessaria.medida_controle_grupo_id
                ).label,
              }
            }
            onChange={(option) => {
              setMedidaNecessariaId(option.value);
            }}
            options={optionsGruposMedidas}
            required
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <label>Prazo:</label>
          <small className="ml-2 text-muted">{"Número de meses"}</small>
          <input
            type="number"
            id="medidaControleNecessaria"
            name="medidaControleNecessaria"
            maxlength="2"
            className="form-control"
            defaultValue={medidaControleNecessaria?.prazo}
            onChange={(m) => {
              setSalvarPrazoMedidaControleNecessaria(m.target.value);
            }}
            required
          />
        </div>
        <div className="col">
          <label htmlFor="tipoMedida">Forma de Acompanhamento:</label>
          <Select
            options={optionsFormaAcompanhamento}
            onChange={(m) => {
              setSalvarFormaAcompanhamentoMedidaControleNecessaria(m.value);
            }}
            id="tipoMedida"
            defaultValue={selecionaFormaAcompanhamento(medidaControleNecessaria?.forma_acompanhamento_id)}
          />
        </div>
      </div>
    </ModalSubmit>
  );
};

export default ModalAcidenteMedidaControleNecessaria;
